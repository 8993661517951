import React, {useEffect, useState} from "react";
import GridTable from "./UI/base/GridTable/components/GridTable";
import {config} from "../config";
import {axiosRun} from "../utils/axiosWrap";
import {Form} from "./UI/base/Form/components/Form";
import {
    useParams
} from "react-router-dom";

import {StudentTabEducation} from "./StudentTabEducation";
import CircularProgress from "@mui/material/CircularProgress";
import Box from '@mui/material/Box';
import SendEmail from "./SendEmail";
import {shallowEqual, useSelector} from "react-redux";


export const UsersForm = ()=>{
    const {id} = useParams();
    const [item, setItem] = useState({});
    const [isLoad, setIsLoad] = useState(true);
    const [isUpdateOnlyData, setIsUpdateOnlyData] = useState(false);
    const [countries,setCountries] = useState([]);
    const [documents,setDocuments] = useState([]);
    const [columns, setColumns] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const roles = useSelector((state) => state.users.user, shallowEqual).user.roles;

    useEffect(()=>{
        if (id && isLoad){
            const getData = async () => {
                const linkDataGet = roles !== 'ADMIN_GROUP_LEAD'?config.apiLinks.getUserItem.replace("%id%",id):config.apiLinks.getUserItemGrLead.replace("%id%",id);
                const itemUser = await axiosRun( linkDataGet , 'GET' )
                setItem(itemUser);
            }
            const loadAllReq = async ()=> {
                const faculties = await axiosRun(roles!=='ADMIN_GROUP_LEAD'?config.apiLinks.getFacultiesList:config.apiLinks.getFacultiesListGroupLead, 'GET');
                setFaculties(faculties);
                setIsLoad(false);
            };

            getData();
            loadAllReq();
        }
    }, [id,isLoad])

    // useEffect(()=>{
    //     setIsLoad(false);
    // }, [item])

    //when all data loaded config columns
    useEffect( () => {
        if (!isLoad && Object.keys(faculties).length>0){

            const columns_ = [
                { id: 'firstName', numeric: false, disablePadding: true, label: "Ім'я", isLinkItem: true, type: "text" },
                { id: 'firstNameEn', numeric: false, disablePadding: true, label: "Ім'я лат", isLinkItem: true, type: "text" },
                { id: 'lastName', numeric: false, disablePadding: false, label: 'Прізвище', type: "text" },
                { id: 'lastNameEn', numeric: false, disablePadding: false, label: 'Прізвище лат', type: "text" },
                { id: 'middleName', numeric: false, disablePadding: false, label: 'По батькові', type: "text" },
                { id: 'middleNameEn', numeric: false, disablePadding: false, label: 'По батькові лат', type: "text" },
                { id: 'email', numeric: true, disablePadding: false, label: "пошта", type: "text"},
                { id: 'ipnNumber', numeric: true, disablePadding: false, label: "ІПН", type: "text"},
                { id: 'contactPhone', numeric: true, disablePadding: false, label: "телефон 1", type: "text"},
                { id: 'homePhone', numeric: true, disablePadding: false, label: "телефон 2", type: "text"},
                { id: 'birthday', numeric: true, disablePadding: false, label: "дата народження", type: "date"},
                { id: 'studentTicketId', numeric: true, disablePadding: false, label: "номер студентського", type: "text"},
                { id: 'studentTicketDateGet', numeric: true, disablePadding: false, label: "Дата студентського", type: "date"},
                {
                    id: 'isEnabled', label: "чи активний?", isRequired: true, entityName: "isEnabled",
                    entityIdentify: "isEnabled",
                    displayType: "Select",
                    type: 'entityList',
                    entityList: [
                        { id: 0, name: "ні" },
                        { id: 1, name: "так" }
                    ]
                },
                {
                    id: 'isApplicant', label: "Абітурієнт?", isRequired: true,
                    entityName: "isApplicant",
                    entityIdentify: "isApplicant",
                    displayType: "Select",
                    type: 'entityList',
                    entityList: [
                        { id: false, name: "ні" },
                        { id: true, name: "так" }
                    ]
                },
                {id:'id_PersonSex', label: "стать", isRequired:true, entityName: "id_PersonSex",
                    entityIdentify: "id_PersonSex",
                    displayType: "Select",
                    type: 'entityList',
                    entityList:[
                        {id:1, name: "Чол"},
                        {id:2, name: "Жін"}
                    ]
                },
            ];
            setColumns(columns_);
        }
    }, [isLoad,faculties])


    const  triggerLinks = {
        editLinkSave: config.apiLinks.addOrEditStudent,
        createLinkSave: config.apiLinks.addOrEditStudent,
        refreshLink: 'products',
        linkItem: '/admin/product/item/%itemId%',
        deleteLink: 'products/remove'
    }

    const triggerAfterSave = ()=>{
        setIsLoad(true);
    }

    return <>
            {<Form
                columns={columns}
                itemWithData={item}
                additionalTriggerAfterSave={triggerAfterSave}
                triggerLinks={triggerLinks}
                formClassName={""}
                buttonClassName={""}
                isLoadData={isLoad}
                tables={
                    [
                        {
                            component:StudentTabEducation,
                            props:{
                                id:{id},
                                faculties
                            },
                            label: "Місце навчання"
                        },
                        {
                            component: SendEmail,
                            props:{
                                userId:id
                            },
                            label: "Відправити емейл"
                        }
                    ]
                }

            />}

    </>

}