
export const config = {
    //apiUrl:'http://192.168.0.104:8581/api/',
    //apiUrl: 'http://192.168.10.204:5901/api/',
    //'apiUrl':'http://192.168.7.211:5901/api/',
    intervalDataUpdate: 5000,
    captchaKey: '6Ld44KkUAAAAAH79ZQKSv4K3RkejgILydCbXVCM5',
    //apiUrl:'http://localhost:8567/api/',
    apiUrl:'https://e.snau.edu.ua/api/',
    socketUrl: 'https://e.snau.edu.ua',
    scanCodeMin: 1,
    scanCodeMax: 300,
    debugScan: true,
    statusesRequest: {1:{ 'new':'new', 'active':'activeReq', 'cancelled':'cancelledReq','notApproved':"notApprovedReq",'done':"approvedReq" },
        0:{'new':"new",'active':'activeSimple', 'done': 'doneSimple' }},
    apiLinks: {
/*        loginUser: 'user/login',
        logout: '/logout',
        getUserInfo: 'user/profile',
        uploadStPhoto: 'user/upload/photo',
	sendEmailToUser: 'user/send/email',
        getUserByHash: 'user/get/by/hash/%hash%',
        checkUser: 'user/restore/check',
        secretSantaSuperAdmin: 'user/generate/something',
        getLogsData: 'user/load/log',
        getLogItem: 'user/get/log/%id%',
        uploadUserFile: 'user/load/from/file',
        uploadUserFileFacultety: 'user/faculty/load/from/file',
        uploadUserAndFacultety: 'user/and/faculty/load/from/file',
        uploadUserEmails: 'user/update/emails',
        registerUser: 'user/try/register',
        getUserList: 'user/list/all',
        getAdminList: 'user/admin/list/all',
        getCountriesList: 'user/countries/all',
        getDocumentsList: 'user/documents/all',
        getFacultiesList: 'user/faculties/list/all',
        getFacultiesgroupList: 'user/get/faculties/spec/groups',
        getFacultiesListClear: 'user/clear/faculties/list',
        resetUser: 'user/reset/user',
        addOrEditStudent: 'user/students/add/or/edit',
        getUserItem: 'user/get/%id%',
        executeMailing: 'user/execute/mailing',
        updateUserPassword: 'user/update/password/by/hash',
        loadGroupsList: 'user/get/groups/list/%data%',
        getMappedGroups: 'user/get/mapped/groups',
        updateStudyPlace: 'user/post/study/place'*/
	getTemplatesByFid: 'user/template/list/%id%',
        getTemplateList: 'admin/templates/list',
        removeStudentsFromGroup: 'admin/groups/student/remove',
        saveNameResource: 'admin/groups/save',
        createGroup: 'admin/groups/add',
        addStudentToGroup: 'admin/group/%id%/add',
        getGroupsList: 'admin/groups/list',
        getGroupItem: 'admin/grp/item/%id%',
        getTemplatesListForStudent: 'user/template/list/%id%',
        getTemplateItem: 'admin/template/%id%',
        loginUser: 'user/login',
        logout: '/logout',
        getUserInfo: 'user/profile',
        uploadStPhoto: 'user/upload/photo',
        sendEmailToUser: 'admin/send/email',
        sendEmailToUserGroupLead: 'user/send/email',
        getUserByHash: 'user/get/by/hash/%hash%',
        checkUser: 'admin/restore/check',
        secretSantaSuperAdmin: 'admin/generate/something',
        getLogsData: 'admin/load/log',
        getLogItem: 'admin/get/log/%id%',
        uploadUserFile: 'admin/load/from/file',
        uploadUserFileFacultety: 'admin/faculty/load/from/file',
        uploadUserAndFacultety: 'admin/and/faculty/load/from/file',
        uploadUserEmails: 'admin/update/emails',
        registerUser: 'user/try/register',
        getUserList: 'admin/list/all',
        getUserListGroupLead: 'user/list/all',
        getAdminList: 'admin/adminentity/list/all',
        getCountriesList: 'admin/countries/all',
        getDocumentsList: 'admin/documents/all',
        getFacultiesList: 'admin/faculties/list/all',
        getFacultiesListGroupLead: 'user/faculties/list/all',
        getFacultiesgroupList: 'admin/get/faculties/spec/groups',
        getFacultiesgroupListGroupLead: 'user/get/faculties/spec/groups',
        getFacultiesListClear: 'admin/clear/faculties/list',
        getRequestsListClear: 'admin/requests/get/',
        resetUser: 'user/reset/user',
        addOrEditStudent: 'admin/students/add/or/edit',
        createTemplate: 'admin/templates',
        editTemplatesItem: 'admin/update/template',
        addOrEditAdmin: 'admin/adminentity/add/or/edit',
        getUserItem: 'admin/get/%id%',
        getUserItemGrLead: 'user/get/%id%',
        executeMailing: 'admin/execute/mailing',
        updateUserPassword: 'user/update/password/by/hash',
        loadGroupsList: 'admin/get/groups/list/%data%',
        getMappedGroups: 'admin/get/mapped/groups',
        getMappedGroupsGroupLead: 'user/get/mapped/groups',
        updateStudyPlace: 'admin/post/study/place',
        updateStudyPlaceGroupLead: 'user/post/study/place',
        getChatRequest: 'user/request/%id%',
        getChatRequestMessages: 'user/messages/request/%id%',
        sendChatRequestMessage: 'user/request/%id%/message',
        createNewRequest: 'user/request/new',
        getRequestList: 'user/requests/list/',
        updateRequestStatus: 'admin/request/status/update',
        downloadFile: 'user/message/file/download',
        studentsForAdding: 'admin/students/for/adding',
	facultyItemLink: 'admin/faculty/%id%',
        facultyItemLinkAdd: 'admin/faculty/link/add',
        removeLinksFromGroup: 'admin/faculty/link/remove',
        getFacultiesLinkList: 'admin/get/faculties/list',
        profileGetLinks: 'user/links',
	getPaymentItem: 'admin/payment/get/1',
        editPaymentItem: 'admin/payment/edit/item/1',
        getPaymentData: 'user/payment/get/%type%',
	getDegreesList: 'admin/degrees/list',
        uploadGroupsData: 'admin/update/groups',
	getBypassFields: 'admin/bypass/list/%id%',
	addToBypass: 'admin/bypass/field/add',
        getDepartmentsList: 'admin/departments/list',
        removeField: 'admin/bypass/field/remove',
        saveBaseInfo: 'admin/bypass/save/base',
        getBypassList: 'admin/bypass/templates/list',
        getStudentsBypassList: 'user/bypass/student/list',
        getBypassFieldsByTemplate: 'user/bypass/template/%id%',
        getBypassFieldsByStudent: 'user/bypass/student/%id%',
        createNewStudentsBypass: 'user/bypass/create',
        getStudentsAllBypassByACL: 'admin/bypass/students/list',
        getBypassFieldsByAdmin: 'admin/bypass/students/item/%id%',
        saveFieldsBypassByAdmin: 'admin/bypass/students/field/updates/%id%',
        getAdministratorData: 'admin/get/data/adminuser/%id%',
        saveAdminData: 'admin/save/adminuser/%id%',
        initiateBypassForGroup:'admin/bypass/send',
        getGroupsByFaculties: 'admin/group/list/fid/%id%',
        getTemplatesByFaculties: 'admin/templates/list/fid/%id%',
        getBypassPdf: 'user/bypass/get/pdf',
        sendNewPassword: 'admin/save/password/new',
        addOrEditDepartment: 'admin/add/or/edit/department/',
        getDeparmentItem: 'admin/get/department/item/%id%',
        getSpecialityList: 'admin/clear/all/speciality/list',
        getSpecializationList:'admin/clear/all/specializations/list',
	removeBypass: 'admin/remove/bypass'
    }
};
