import { Suspense } from 'react';
import React, {  useEffect } from 'react';
import AxiosErrorHandler from "../utils/AxiosErrorHandler";

import {  BrowserRouter,Routes, Route, Link } from "react-router-dom";
import {AdminFacultyRoute, PrivateRoute, PublicRoute, SuperPrivateRoute} from "./syst/CustomRoute";
import {useDispatch} from "react-redux";
import {restoreAuthData} from "../slices/usersSlice";
import {Profile} from "../components/Profile";
import {Logout} from "../components/Logout";
import {Login} from "../components/Login";
import {MainWrapper} from "../components/MainWrapper";
import {Dashboard} from "../components/Dashboard";
import {Courses} from  "../components/Courses";
import {Admissions} from "../components/Admissions";
import { Tasks } from '../components/Tasks';
import { Calendar } from '../components/Calendar';
import { Finances } from '../components/Finances';
import { HelpCenter } from '../components/HelpCenter';
import {UploadData} from "../components/UploadData";
import {LogList} from "../components/LogList";
import {MainWrapperAdmin} from "../components/MainWrapperAdmin";
import {StudentsLogin} from "../components/StudentsLogin";
import {SuperAdminLogin} from "../components/SuperAdminLogin";
import {Register} from "../components/UM/Register/Register";
import {Reset} from "../components/UM/Reset/Reset";
import {UsersList} from "../components/UsersList";
import {UsersForm} from "../components/UsersForm";
import {UploadFacultety} from "../components/UploadFacultety";
import {GenerateUserFaculties} from "../components/GenerateUsersFaculties";
import {UploadDataBoth} from "../components/UploadDataBoth";
import {LogItem} from "../components/LogItem";
import {AdminsList} from "../components/AdminsList";
import {Mailing} from "../components/Mailing";
import SetPassword from "../components/UM/SetPassword";
import EditProfile from "../components/EditProfile";
import {UpdateUsersEmails} from "../components/UpdateUsersEmails";
import ErrorBoundary from "../components/ErrorBoundary";
import Error404page from "../components/Error404page";
import {TemplatesList} from "../components/TemplatesList";
import {TemplateForm} from "../components/TemplateForm";
import RequestForm from "../components/RequestForm";
import RequestFormStudent from "../components/RequestFormStudent";
import {RequestsList} from "../components/RequestsList";
import RequestsListSudent from "../components/RequestsListSudent";
import {GroupsList} from "../components/GroupsList";
import GroupItem from "../components/GroupItem";
import GroupItemNew from "../components/GroupItemNew";
import {Financial} from "../components/Financial";
import Faculty from "../components/Faculty";
import {FacultiesLinkList} from "../components/FacultiesLinkList";
import {LinkList} from "../components/LinkList";
import {PaymentItem} from "../components/PaymentItem";
import {UpdateGroupsData} from "../components/UpdateGroupsData";
import {FieldsList} from "../components/BypassList/FieldsList";
import {BypassesList} from "../components/BypassList/BypassesList";
import CheckBypassList from "../components/CheckBypassList";
import CheckBypassItem from "../components/CheckBypassItem";
import {StudentsBypassList} from "../components/StudentsBypassList";
import CheckBypassItemAdmin from "../components/CheckBypassItemAdmin";
import AdministratorItem from "../components/UM/admin/AdministratorItem";
import {AdminBypassSender} from "../components/AdminBypassSender";
import {DepartmentList} from "../components/BypassList/DepartmentList";
import DepartmentItem from "../components/BypassList/DepartmentItem";

/**
 * Define routes with some redirect according presenting data about user (isLogged = true|false|null)
 * This data we get from redux store.
 *
 * Null - haven't info
 * true - logged
 * false - not logged
 *
 *
 * @return {*}
 * @constructor
 */
const Routing = ()=>{
    const dispatch = useDispatch();
    useEffect( ()=>{
        dispatch(restoreAuthData())
    }, [dispatch])

    return(
        <BrowserRouter>
            <Suspense fallback="loading">
                <Routes>
                    {/*login routes*/}
                    <Route exact={true} path={"/:lang"} element={<PublicRoute><StudentsLogin /></PublicRoute>} />
                    <Route exact={true} path={"/"} element={<AxiosErrorHandler><PublicRoute><StudentsLogin /></PublicRoute></AxiosErrorHandler>} />
                    <Route exact={true} path={"/superadmin/login"} element={<PublicRoute><SuperAdminLogin /></PublicRoute>} />
                    {/*end block*/}

                    {/*students routes*/}
                    <Route path={"profile"} element={<MainWrapper />}>
                        <Route exact={true} path={"info"} element={<AxiosErrorHandler><PrivateRoute><Profile /></PrivateRoute></AxiosErrorHandler>} />
                        <Route exact={true} path={"dashboard"} element={<Dashboard />} >
                        </Route>
                        <Route exact={true} path={"request/chat/new"} element={<PrivateRoute><RequestFormStudent /></PrivateRoute>} />
                        <Route exact={true} path={"request/chat/:id"} element={<PrivateRoute><RequestFormStudent /></PrivateRoute>} />
                        <Route exact={true} path={"requests"} element={<PrivateRoute><RequestsListSudent /></PrivateRoute>} />
                        <Route exact={true} path={"financial/:type/reports"} element={<PrivateRoute><Financial /></PrivateRoute>} />

                        <Route  path={"courses"} element={<Courses /> } />
                        <Route  path={"admissions"} element={<Admissions /> } />
                        <Route  path={"tasks"} element={<Tasks /> } />
                        <Route  path={"calendar"} element={<Calendar /> } />
                        <Route  path={"finances"} element={<Finances /> } />
                        <Route  path={"help"} element={<HelpCenter /> } />
                        <Route exact={true} path={"bypass/lists"} element={<PrivateRoute><CheckBypassList /></PrivateRoute>} />
                        <Route exact={true} path={"bypass/template/:templateId/:bypassId"} element={<PrivateRoute><CheckBypassItem /></PrivateRoute>} />
                        <Route exact={true} path={"bypass/template/:templateId/:bypassId/:speciality/:specialization"} element={<PrivateRoute><CheckBypassItem /></PrivateRoute>} />
                        <Route exact={true} path={"links"} element={<PrivateRoute><LinkList /></PrivateRoute>} />
                    </Route>
                    <Route path={":lang/profile"} element={<MainWrapper />}>
                        <Route exact={true} path={"info"} element={<PrivateRoute><Profile /></PrivateRoute>} />
                        <Route exact={true} path={"request/chat/new"} element={<PrivateRoute><RequestFormStudent /></PrivateRoute>} />
                        <Route exact={true} path={"request/chat/:id"} element={<PrivateRoute><RequestFormStudent /></PrivateRoute>} />
                        <Route exact={true} path={"requests"} element={<PrivateRoute><RequestFormStudent /></PrivateRoute>} />
                        <Route exact={true} path={"financial/study/reports"} element={<PrivateRoute><RequestsListSudent /></PrivateRoute>} />
                        <Route exact={true} path={"financial/rent/reports"} element={<PrivateRoute><RequestsListSudent /></PrivateRoute>} />
                        <Route exact={true} path={"links"} element={<PrivateRoute><LinkList /></PrivateRoute>} />
                        <Route exact={true} path={"links"} element={<PrivateRoute><CheckBypassList /></PrivateRoute>} />

                        <Route exact={true} path={"dashboard"} element={<Dashboard />} >

                        </Route>
                        <Route  path={"courses"} element={<Courses /> } />
                        <Route  path={"admissions"} element={<Admissions /> } />
                        <Route  path={"tasks"} element={<Tasks /> } />
                        <Route  path={"calendar"} element={<Calendar /> } />
                        <Route  path={"finances"} element={<Finances /> } />
                        <Route  path={"help"} element={<HelpCenter /> } />
                    </Route>
                    {/*end block*/}
                    <Route path={":lang/registration/exist/student/"} element={<Register/>} />
                    <Route path={"/registration/exist/student/"} element={<Register/>} />
                    <Route path={":lang/reset"} element={<Reset />} />
                    <Route path={"/reset"} element={<Reset />} />
                    <Route path={":lang/set/new/password/:hash"} element={<AxiosErrorHandler><SetPassword /></AxiosErrorHandler>} />
                    <Route path={"/set/new/password/:hash"} element={<AxiosErrorHandler><SetPassword /></AxiosErrorHandler>} />

                    {/*super administration block*/}
                    <Route path={"/administration"} element={<MainWrapperAdmin />}>
                        <Route exact={true} path={"templates/list"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><TemplatesList /></AdminFacultyRoute></AxiosErrorHandler>} />
                        <Route exact={true} path={"bypass/templates/item/:id"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><FieldsList /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"department/item/:id"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><DepartmentItem /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"bypass/templates/list"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><BypassesList /></AdminFacultyRoute></AxiosErrorHandler>} />
                        <Route exact={true} path={"groups/list"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><GroupsList /></AdminFacultyRoute></AxiosErrorHandler>} />
                        <Route exact={true} path={"template/list/:id"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><TemplateForm /></AdminFacultyRoute></AxiosErrorHandler>} />
                       <Route exact={true} path={"group/list/:id"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><GroupItem /></AdminFacultyRoute></AxiosErrorHandler>} />
                        <Route exact={true} path={"groups/new"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><GroupItemNew /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"sender/bypass/"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><AdminBypassSender /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"bypass/list"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><StudentsBypassList /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"departments/list"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><DepartmentList /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"bypass/item/:id"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><CheckBypassItemAdmin /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"administrator/item/:id"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><AdministratorItem /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"faculty/:id"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><Faculty /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"payment/edit"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><PaymentItem /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"faculties/list"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><FacultiesLinkList /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"upload/users/"}
                               element={<SuperPrivateRoute><UploadData /></SuperPrivateRoute>} />
                        <Route exact={true} path={"/administration/upload/facultetu/"}
                               element={<SuperPrivateRoute><UploadFacultety /></SuperPrivateRoute>} />

                        <Route exact={true} path={"/administration/requests/list"}
                               element={<AdminFacultyRoute><RequestsList /></AdminFacultyRoute>} />

                        <Route exact={true} path={"/administration/chat/request/new"}
                               element={<AdminFacultyRoute><RequestForm /></AdminFacultyRoute>} />

                        <Route exact={true} path={"/administration/chat/request/:id"}
                               element={<AdminFacultyRoute><RequestForm /></AdminFacultyRoute>} />


                        <Route exact={true} path={"/administration/generate/administrators"}
                               element={<SuperPrivateRoute><GenerateUserFaculties /></SuperPrivateRoute>} />

                        <Route exact={true} path={"/administration/upload/update/users/email/"}
                               element={<SuperPrivateRoute><UpdateUsersEmails /></SuperPrivateRoute>} />

                        <Route exact={true} path={"/administration/upload/data/groups/"}
                               element={<SuperPrivateRoute><UpdateGroupsData /></SuperPrivateRoute>} />


                        <Route exact={true} path={"/administration/upload/users/and/faculty"}
                               element={<SuperPrivateRoute><UploadDataBoth /></SuperPrivateRoute>} />

                        <Route exact={true} path={"log/list/"}
                               element={<SuperPrivateRoute><LogList /></SuperPrivateRoute>} />

                        <Route exact={true} path={"log/item/:id"}
                               element={<SuperPrivateRoute><LogItem /></SuperPrivateRoute>} />

                        <Route exact={true} path={"users/list"}
                               element={<AxiosErrorHandler><AdminFacultyRoute> <UsersList /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"mailing"}
                               element={<AxiosErrorHandler><AdminFacultyRoute><Mailing /></AdminFacultyRoute></AxiosErrorHandler>} />

                        <Route exact={true} path={"admin/users/list"}
                               element={<SuperPrivateRoute><AdminsList /></SuperPrivateRoute>} />

                        <Route exact={true} path={"users/item/:id"}
                               element={<AdminFacultyRoute><AxiosErrorHandler><UsersForm /></AxiosErrorHandler></AdminFacultyRoute>} />
                    </Route>
                    {/*end blocks*/}

                    <Route exact={true} path={"/logout"} element={<PrivateRoute><Logout /></PrivateRoute>} />
                    <Route path={"*"} element={<Error404page /> } />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}
export default Routing;